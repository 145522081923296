<template>
  <v-container fluid class="pa-16">
    <v-row>
      <v-col>
        <h2>Incident Report</h2>
      </v-col>
    </v-row>
    <v-row align="center">
      <v-col cols="12" md="2">
        <v-select
          :items="monthList"
          label="Month"
          outlined
          dense
          v-model="selectedMonth"
          style="z-index: 500"
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="6" class="d-flex justify-end">
        <download-excel
          :data="json_data"
          :fields="json_fields"
          worksheet="Downloaded Report"
          name="Report.xls"
        >
          <v-btn @click="validateDownload(csvData)" id="button">
            <v-icon left dark>mdi-cloud-download</v-icon>
            Download Excel
          </v-btn>
        </download-excel>
        <v-btn
          id="button"
          tile
          @click="createIncidentPage"
          class="reportButton ml-4 pl-6"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ buttonTitle }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <IncidentList
        :create="createIncidentPage"
        :buttonTitle="buttonTitle"
        :headers="headers"
        :tableItems="incidents"
        :loader="loader"
        v-on:viewDetails="getIncidentDetails"
      ></IncidentList>
    </v-row>
  </v-container>
</template>

<script>
import IncidentService from "../../services/IncidentService";
import IncidentList from "../../components/DataListPage.vue";
import moment from "moment";
export default {
  name: "Incident",
  data: () => ({
    headers: [
      { text: "Id", align: "left", sortable: true, value: "incidentId" },
      {
        text: "Shop",
        align: "left",
        sortable: true,
        value: "shopId",
      },
      {
        text: "Issue",
        align: "left",
        sortable: true,
        value: "IncidentIssueCategory.IssueType.name",
      },
      {
        text: "Category",
        align: "left",
        sortable: true,
        value: "IncidentIssueCategory.name",
      },
      {
        text: "Critical Level",
        align: "left",
        sortable: true,
        value: "criticalLevelValue",
      },
      {
        text: "Open Date",
        align: "left",
        sortable: true,
        value: "openDate",
      },
      {
        text: "Close Date",
        align: "left",
        sortable: false,
        value: "closedDate",
      },
      {
        text: "SLA",
        align: "left",
        sortable: false,
        value: "sla",
      },
      {
        text: "Actions",
        align: "left",
        sortable: false,
        value: "action",
      },
    ],
    title: "Incident Report",
    buttonTitle: "Create Incident",
    selectedClientId: "",
    selectedMonth: "This Month",
    incidents: [],
    json_data: [],
    json_fields: {
      Id: "incidentId",
      "Shop ID": "shopId",
      "Issue": "IncidentIssueCategory.IssueType.name",
      "Category": "IncidentIssueCategory.name",
      "Description": "description",
      "Critical Level": "criticalLevelValue",
      "Reporter": "reporter",
      "Status": "status",
      "Open Date": "openDate",
      "Close Date": "closedDate",
      "SLA": "sla",
    },
    json_meta: [
      [
        {
          key: "charset",
          value: "utf-8",
        },
      ],
    ],
    monthList: ["This Month", "Last Month"],
    currentMonth: true,
    loader: false,
  }),
  components: { IncidentList },
  created() {
    if (!this.selectedClientId) {
      this.selectedClientId = localStorage.getItem("selectedClientId");
    }
    this.getIncidentReport();
  },
  watch: {
    selectedMonth() {
      if (this.selectedMonth == "Last Month") {
        this.currentMonth = false;
      } else {
        this.currentMonth = true;
      }
      this.getIncidentReport();
    },
    selectedClientId() {
      this.getIncidentReport();
    },
  },
  mounted() {
    window.addEventListener(
      "selectedClientId-localstorage-changed",
      (event) => {
        this.selectedClientId = event.detail.storage;
      }
    );
  },
  computed: {
    csvData() {
      return this.incidents.map((item) => ({
        ...item,
      }));
    },
  },
  methods: {
    async getIncidentReport() {
      this.incidents = [];
      let month = moment().format("M");
      if (!this.currentMonth) {
        month = moment().subtract(1, "months").format("M");
      }
      let requiredObj = {
        year: moment().format("YYYY"),
        month,
        projectId: this.selectedClientId,
      };
      await IncidentService.fetchIncidentRecord(requiredObj).then(
        (response) => {
          let criticalLevel = [
            { id: 4, name: "Low" },
            { id: 3, name: "Medium" },
            { id: 2, name: "High" },
            { id: 1, name: "Critical" },
          ];
          let incidentId = "";
          let id = "";
          var currentLevel = "";
          var closedDate = null;
          var openDate = null;
          let requiredData = response;
          requiredData = requiredData.map((item) => {
            id = item.id.toString();
            while (id.length < 3) {
              id = "0" + id;
            }
            incidentId =
              this.selectedClientId.split("-")[0].toUpperCase() +
              "-" +
              moment().format("YYYY-MM") +
              "-" +
              id;
            criticalLevel.forEach((level) => {
              if (level.id == item.criticalLevel) {
                currentLevel = level.name;
              }
              if (!item.sla) {
                item.sla = "-";
              }
            });
            openDate = moment(item.createdAt).format("lll");

            if (item.closedAt) {
              closedDate = moment(item.closedAt).format("lll");
            } else {
              closedDate = "-";
            }
            return {
              incidentId,
              criticalLevelValue: currentLevel,
              openDate,
              closedDate,
              ...item,
            };
          });
          this.incidents = requiredData;
        }
      );
    },
    getIncidentDetails(id) {
      this.$router.push(`/incident/detail/${id}`);
    },
    createIncidentPage() {
      this.$router.push(`/incident/create`);
    },
    validateDownload(csvData) {
      this.csvExport(csvData);
    },
    csvExport(arrData) {
      this.json_data = arrData;
      console.log(this.json_data);
    },
  },
};
</script>
