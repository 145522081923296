<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      hide-overlay
      app
      stateless
      fixed
      value="true"
    >
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="@/assets/baksters.png"></v-img>
        </v-list-item-avatar>
      </v-list-item>
      <v-divider />

      <v-list>
        <div v-for="item in items" :key="item.title">
          <!-- <v-divider v-if="item.title == 'Task'"></v-divider> -->
          <v-list-item v-if="!item.subItems" v-on:click="clicked(item.path)">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <div v-else>
            <!-- <template> -->
            <v-list-item v-on:click="showSubItems = !showSubItems">
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              <v-spacer></v-spacer>
              <v-icon>mdi-chevron-down</v-icon>
            </v-list-item>
            <!-- </template> -->

            <template v-if="showSubItems">
              <v-list-item
                class="pl-10"
                v-for="subItem in item.subItems"
                :key="subItem.title"
                v-on:click="clicked(subItem.path)"
              >
                <v-list-item-action>
                  <v-icon>{{ subItem.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item>
            </template>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="white" light short style="z-index: 1000">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-row class="d-flex align-center">
        <v-col cols="12" md="3">
          <v-toolbar-title> BAKSTERS CARE </v-toolbar-title>
        </v-col>
        <v-col md="4" class="d-flex align-center justify-start mt-sm-0 mt-md-5">
          <v-toolbar-items class="hidden-sm-and-down">
            <ClientList></ClientList>
          </v-toolbar-items>
          <!-- <ClientList></ClientList> -->
        </v-col>
      </v-row>
      <v-spacer></v-spacer>
      <v-col md="4" class="d-flex justify-end">
        <v-menu
          transition="slide-y-transition"
          bottom
          :nudge-width="250"
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn depressed icon class="mr-4" v-on="on">
              <v-icon large>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-card class="cardBorderRadious">
            <v-list>
              <v-list-item-content class="pa-3">
                <v-list-item-subtitle><b>Hello,</b></v-list-item-subtitle>
                <v-list-item-title> Admin</v-list-item-title>
              </v-list-item-content>
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text title="Logout" color="grey darken-1" @click="logout">
                <v-icon class="mr-1">mdi-lock-open</v-icon>
                Log out
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-col>
    </v-app-bar>
  </div>
</template>

<script>
import ClientList from "../components/SelectClient.vue";
export default {
  name: "Header",
  components: { ClientList },
  data: () => ({
    drawer: false,
    showSubItems: false,
    items: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        href: "/dashboard",
        path: "/dashboard",
      },
      {
        title: "Live Status",
        icon: "mdi-home",
        href: "/live-status",
        path: "/live-status",
      },
      {
        title: "Stability",
        icon: "mdi-file-arrow-up-down-outline",
        href: "/stability",
        path: "/stability",
      },
      {
        title: "Incidents",
        icon: "mdi-alert-box-outline",
        subItems: [
          {
            title: "Dashboard",
            icon: "mdi-view-dashboard-outline",
            href: "/incident/dashboard",
            path: "/incident/dashboard",
          },
          {
            title: "Report",
            icon: "mdi-file-chart-outline",
            href: "/incident/report",
            path: "/incident/report",
          },
        ],
      },
    ],
    mini: true,
  }),
  created() {},
  mounted() {},
  methods: {
    clicked(path) {
      this.$router.push({ path: path });
      this.drawer = false;
    },
    logout() {
      this.$router.push("/");
      localStorage.clear();
      window.location.reload();
    },
  },
};
</script>
<style>
.toolbar-color {
  background-color: #0d97f4 !important;
}

.icon-color .v-btn__content {
  color: #ffffff;
}

.icon-flipped {
  transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
}
</style>