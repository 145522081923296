import APIManager from "./APIManager";
import axios from "axios";

const fetchStatusRecordByCamera = async (projectId) => {
    const status = await APIManager.axios
      .get(`/v1/operation/status?projectId=${projectId}`)
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  
    return status;
  };

  const fetchStatusRecordByStream = async () => {
    const status = await axios
      .get(`/v1/operation/sensor`)
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  
    return status;
  };
  export default {
    fetchStatusRecordByCamera,
    fetchStatusRecordByStream
  };
  