import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from "vue-easytable";
import JsonExcel from "vue-json-excel";
import Vue2Breadcrumbs from 'vue-2-breadcrumbs'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(VueEasytable);
Vue.component('vue2-breadcrumbs', Vue2Breadcrumbs)

Vue.component("downloadExcel", JsonExcel);
