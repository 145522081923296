<template>
    <v-tooltip bottom content-class="custom-tooltip-style" v-model="show" :open-on-hover="false">
        <template v-slot:activator="{ on }">
            <span v-on="on" @click="show = !show" @mouseleave="closeToolTip">
                <StatusIndicator :status="status" :class="{'partial' : status == 'intermediary'}"></StatusIndicator>
            </span>

        </template>
        <v-simple-table dense style="width: 100%;">
            <thead>
                <tr>
                    <th class="text-left" style="padding-right: 100px;">
                        Hour
                    </th>
                    <th class="text-left">
                        Status
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item,index in branchRecords" :key="index">                   
                    <td v-if="String(parseInt(shopData['openingTime']) + index).length == 1">0{{ parseInt(shopData['openingTime']) + index}}:00 - {{ parseInt(shopData['openingTime']) + index + 1 }}:00</td>
                    <td v-else>{{ parseInt(shopData['openingTime']) + index}}:00 - {{ parseInt(shopData['openingTime']) + index + 1 }}:00</td>
                    <td class="status">
                        <span :class="{'negative-data': item.record == 0, 'active-data': item.record == 1, 'partial': item.record == 2}"></span>
                    </td>
                </tr>
            </tbody>
        </v-simple-table> 
    </v-tooltip>
</template>

<script>
import { StatusIndicator } from "vue-status-indicator";

export default {
    name: "ToolTip",
    props: {
        status_: Number,
        branchRecords: Array,
        shopData: Object
    },
    data: () => ({
        status: null,
        show: false,
        headers: []
    }),
    components:{
        StatusIndicator,
    },
    created() {
        if (this.status_ == 1) {
            this.status = "active"
        }
        else if (this.status_ == 2) {
            this.status = "intermediary"
        }
        else if (this.status_ == 0) {
            this.status = "negative"
        }
    },
    methods: {
        closeToolTip(){
            this.show = false;
        },
    }
}
</script>

<style scoped>

:root {
  --status-indicator-size: 20px;
  --status-indicator-color-active: #4bd28f !important;
  --status-indicator-color-negative: #ff4d4d !important;
  --status-indicator-color-positive: rgb(75, 89, 210) !important;
  --status-indicator-color-partial:#FF9800 !important;
}

.status span{
    display: block;
    height: 10px;
    width: 10px;
    background-color: #dadada;
    border-radius: 100%;
    margin: 0 auto;
}

.negative-data{
    background-color: #ff4d4d !important;
}

.active-data{
    background-color: #4bd28f !important;
}

.partial{
    background: #FF9800 !important;
}

.custom-tooltip-style{
    background-color: #fdfdfd;
    color: black;
    opacity: 1;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    z-index: 50 !important;
}
</style>