<template>
  <v-app>
    <v-main>
      <app-header v-if="!$route.meta.hideNavbar"/>
      <!-- <vue2-breadcrumbs /> -->
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Header from "./components/Header.vue";

export default {
  name: "App",
  components: {
    "app-header": Header,
    //s"app-footer": Footer,
  },
};
</script>

<style>
@import "assets/style.css";
.v-main__wrap{
  background-color: #F5F5F5;
  overflow: hidden;
}
</style>
