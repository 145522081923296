<template>
  <v-select
    :items="projectList"
    :item-text="'name'"
    :item-value="'id'"
    outlined
    placeholder="Client"
    dense
    v-model="selectedClientId"
  ></v-select>
</template>

<script>
import ProjectService from "../services/ProjectService";
export default {
  data: () => ({
    projectList: [],
    selectedClientId: ""
  }),
  props: {
    // getStabilityRecord: {type: Function}
  },
  created(){
     if (!this.selectedClientId) {
      this.selectedClientId = localStorage.getItem("selectedClientId");
    }
    this.getProjectRecord();
  },
  watch: {
    selectedClientId(){
      localStorage.setItem("selectedClientId", this.selectedClientId);
      window.dispatchEvent(
        new CustomEvent("selectedClientId-localstorage-changed", {
          detail: {
            storage: localStorage.getItem("selectedClientId"),
          },
        })
      );
    }
  },
  methods: {
    getProjectRecord() {
      ProjectService.fetchProjectRecord().then((response) => {
        let requiredData = response;
        this.projectList = requiredData;
      });
    },
  },
};
</script>