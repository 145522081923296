<template>
  <v-container fluid class="pa-16">
    <!-- <v-layout> -->
    <v-dialog v-model="deactivateDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Deactivate Confirmation</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <p>Are you sure you want to deactivate this task?</p>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deactivateStream()"> Yes </v-btn>
          <v-btn text @click="deactivateDialog = false"> No </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="breadcrumbItems" class="pa-0 ma-0">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h2>Shop Details for {{ shopDetails.id }} {{ shopDetails.name }}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" sm="6">
                <strong>Shop Address</strong>
              </v-col>
              <v-col cols="12" sm="6">
                {{ shopDetails.address }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <strong>Shop Size</strong>
              </v-col>
              <v-col cols="12" sm="6">
                {{ shopDetails.area }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <strong>Shop Group</strong>
              </v-col>
              <v-col cols="12" sm="6">
                {{ shopDetails.group }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <strong>Shop Province</strong>
              </v-col>
              <v-col cols="12" sm="6">
                {{ shopDetails.province }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" sm="6">
                <strong>Opening Time</strong>
              </v-col>
              <v-col cols="12" sm="6">
                {{ shopDetails.openingTime }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <strong>Closing Time</strong>
              </v-col>
              <v-col cols="12" sm="6">
                {{ shopDetails.closingTime }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <strong>Shop Status</strong>
              </v-col>
              <v-col cols="12" sm="6">
                {{ shopDetails.status }}
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <strong>Remarks</strong>
              </v-col>
              <v-col cols="12" sm="6">
                {{ shopDetails.remarks }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Stream Cards -->
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                xl="4"
                v-for="stream in shopDetails.Streams"
                :key="stream.id"
              >
                <v-card class="mx-auto pa-0 rounded-lg" outlined>
                  <div>
                    <v-img
                      :src="`data:image/jpg;base64,${stream.snapshot}`"
                      style="height: 250px"
                      alt="Stream"
                    >
                    </v-img>
                  </div>
                  <v-card-title
                    >{{ stream.shopId }} - {{ stream.cameraId }}</v-card-title
                  >
                  <v-card-text>
                    <div>
                      <v-chip small id="chip" :class="stream.status">{{
                        stream.status
                      }}</v-chip>
                    </div>
                    <br />
                    <div>
                      <strong>Username: </strong>
                      {{ stream.username }}
                    </div>
                    <div>
                      <strong>Password: </strong>
                      {{ stream.password }}
                    </div>
                    <div>
                      <strong>Stream URL: </strong>
                      {{ stream.streamUrl }}
                    </div>
                    <div>
                      <strong>Status: </strong>
                      {{ stream.status }}
                    </div>
                    <br />
                    <div class="d-flex justify-end">
                      <v-btn
                        :disabled="!stream.active"
                        id="button"
                        small
                        class="rounded"
                        @click="showDeactivateDialog(stream.id)"
                        >Deactivate</v-btn
                      >
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- </v-layout> -->
  </v-container>
</template>

<script>
import ShopService from "@/services/ShopService";
import StreamService from "@/services/StreamService";
export default {
  data: () => ({
    selectedShopId: "",
    selectedStreamId: "",
    selectedItem: [],
    shopDetails: {},
    deactivateDialog: false,
    isDeactive: false,
    breadcrumbItems: [
      {
        text: "Dashboard",
        href: "/#/dashboard",
        meta: {
          isAuth: true,
        },
      },
      {
        text: "Shop Details",
        disabled: true
      },
    ],
  }),
  created() {
    this.getShopDetails();
  },
  methods: {
    async getShopDetails() {
      let selectedShopId = this.$route.params.id;
      const shopId = selectedShopId;
      await ShopService.getShop(shopId).then((response) => {
        let requiredObj = response;
        if (requiredObj.active) {
          requiredObj.status = "Active";
        } else {
          requiredObj.status = "Inactive";
        }
        if (!requiredObj.remarks) {
          requiredObj.remarks = "N/A";
        }
        requiredObj.Streams.map((stream) => {
          if (stream.active) {
            stream.status = "Active";
          } else {
            stream.status = "Inactive";
          }
        });
        this.shopDetails = requiredObj;
      });
    },
    showDeactivateDialog(streamId) {
      this.selectedStreamId = streamId;
      this.deactivateDialog = true;
    },
    async deactivateStream() {
      let shopId = this.$route.params.id;
      let streamId = this.selectedStreamId;
      await StreamService.deleteStream(shopId, streamId).then(() => {
        this.getShopDetails();
      });
      this.deactivateDialog = false;
    },
  },
};
</script>
<style>
.v-breadcrumbs li {
    font-size: 1rem;
}
</style>
