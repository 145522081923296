import APIManager from "./APIManager";

const fetchShopRecord = async (projectId) => {
    const shop = await APIManager.axios
      .get(`/v1/operation/shop/?projectId=${projectId}`)
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  
    return shop;
  };

  const getShop = async (shopId) => {
    const shop = await APIManager.axios
        .get(`/v1/operation/shop/${shopId}`)
        .then((response) => {
            return response.data.data;
        })
        .catch((err) => {
            return err.response.data;
        });

    return shop;
};

  export default {
    fetchShopRecord,
    getShop
  };
  