<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'
import {  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'DoughnutChart',
  components: { Doughnut },
  data: () => ({
    chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
  }),
  props: {
    chartData: { type: Object },
    width: {
      type: Number,
      default: 350
    },
    height: {
      type: Number,
      default: 350
    },
  },
}
</script>