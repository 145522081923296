import APIManager from "./APIManager";

const fetchProjectRecord = async () => {
    const project = await APIManager.axios
      .get(`/v1/operation/project`)
      .then((response) => {
        return response.data.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  
    return project;
  };

  const getProject = async (projectId) => {
    const project = await APIManager.axios
        .get(`/v1/operation/project/${projectId}`)
        .then((response) => {
            return response.data.data;
        })
        .catch((err) => {
            return err.response.data;
        });

    return project;
};

  export default {
    fetchProjectRecord,
    getProject
  };
  