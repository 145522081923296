<template>
  <v-container fluid class="pa-16">
    <v-row>
      <v-col cols="12">
        <h2>Incident Dashboard</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="2" sm="3">
        <!-- <v-row class="d-flex align-center justify-center" v-if="!dataLoaded"> -->
        <!-- </v-row> -->
        <v-select
          :items="monthList"
          label="Month"
          outlined
          dense
          v-model="selectedMonth"
          style="z-index: 500"
        ></v-select>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-center" v-if="!dataLoaded">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>Incidents by Issue Type</v-card-title>
          <v-card-text>
            <v-row
              class="d-flex align-center justify-center"
              v-if="!issueLoaded"
            >
              <v-progress-circular indeterminate></v-progress-circular>
            </v-row>
            <BarChart
              v-else
              :chartData="chartDataByIssue"
              :chartOptions="options"
            ></BarChart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>Incidents by Status</v-card-title>
          <v-card-text>
            <v-row
              class="d-flex align-center justify-center"
              v-if="!statusLoaded"
            >
              <v-progress-circular indeterminate></v-progress-circular>
            </v-row>
            <BarChart
              v-else
              :chartData="chartDataByStatus"
              :chartOptions="options"
            ></BarChart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>Incidents by Reporter</v-card-title>
          <v-card-text>
            <v-row
              class="d-flex align-center justify-center"
              v-if="!reporterLoaded"
            >
              <v-progress-circular indeterminate></v-progress-circular>
            </v-row>
            <DoughnutChart
              v-else
              :chartData="chartDataByReporter"
            ></DoughnutChart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Incidents by Shop</v-card-title>
          <v-card-text>
            <v-row
              class="d-flex align-center justify-center"
              v-if="!shopLoaded"
            >
              <v-progress-circular indeterminate></v-progress-circular>
            </v-row>
            <BarChart
              v-else
              :chartData="chartDataByShop"
              :chartOptions="options"
            ></BarChart>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BarChart from "../../components/BarChart.vue";
import DoughnutChart from "../../components/DoughtnutChart.vue";
import IncidentService from "../../services/IncidentService";
import ProjectService from "../../services/ProjectService";
import IssueService from "../../services/IssueService";
import moment from "moment";
export default {
  name: "IncidentDashboard",
  components: { BarChart, DoughnutChart },
  data: () => ({
    selectedClientId: "",
    selectedMonth: "This Month",
    incidents: [],
    shopList: [],
    issueList: [],
    issueListCount: [],
    monthList: ["This Month", "Last Month"],
    currentMonth: true,
    issueLoaded: false,
    statusLoaded: false,
    reporterLoaded: false,
    dataLoaded: false,
    shopLoaded: false,
    chartDataByIssue: {},
    chartDataByReporter: {},
    chartDataByShop: {},
    chartDataByStatus: {},
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 100,
    },
    options: {
      plugins: {
        datalabels: {
          display: false,
          align: "end",
          anchor: "end",
          clamp: true,
          clip: true,
        },
        legend: {
          display: false,
        },
      },
      responsive: true,
      maintainAspectRatio: false,
      pointRadius: 0.5,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            let label = data.datasets[tooltipItem.datasetIndex].label || "";
            if (label) {
              label += ": ";
            }
            label += tooltipItem.yLabel.toFixed(0);
            return label;
          },
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: "Number of Incidents",
            position: "right",
          },
          ticks: {
            stepSize: 1,
            min: 0,
          },
        },
      },
    },
  }),
  mounted() {
    window.addEventListener(
      "selectedClientId-localstorage-changed",
      (event) => {
        this.selectedClientId = event.detail.storage;
      }
    );
  },
  watch: {
    selectedMonth() {
      this.dataLoaded = false;
      if (this.selectedMonth == "Last Month") {
        this.currentMonth = false;
      } else {
        this.currentMonth = true;
      }
      this.getIncidentReport(this.selectedClientId);
      // if(this.issueLoaded && this.statusLoaded && this. )
      // this.dataLoaded = true;
    },
    selectedClientId() {
      this.getIncidentReport();
    },
  },
  created() {
    // this.getIssueType();
    if (!this.selectedClientId) {
      this.selectedClientId = localStorage.getItem("selectedClientId");
    }
  },
  methods: {
    async getIssueType() {
      this.issueList = [];
      await IssueService.fetchIssueRecord().then((response) => {
        let issueList = response;
        issueList.forEach((item) => {
          this.issueList.push(item.name);
        });
      });
    },
    async getShopList() {
      this.shopList = [];
      await ProjectService.fetchProjectRecord().then((response) => {
        let projects = response;
        projects.forEach((project) => {
          if (this.selectedClientId == project.id) {
            this.shopList = project.Shops;
          }
        });
      });
    },
    async getIncidentReport() {
      this.incidents = [];
      let month = moment().format("M");
      if (!this.currentMonth) {
        month = moment().subtract(1, "months").format("M");
      }
      let requiredObj = {
        year: moment().format("YYYY"),
        month,
        projectId: this.selectedClientId,
      };
      await IncidentService.fetchIncidentRecord(requiredObj).then(
        (response) => {
          let requiredData = response;
          this.incidents = requiredData;
          this.getChartValuesforIssue();
          this.getChartValuesforReporter();
          this.getChartValuesforShop();
          this.getChartValuesforStatus();
        }
      );
      this.dataLoaded = true;
    },
    async getChartValuesforIssue() {
      await this.getIssueType();
      let incidents = this.incidents;
      let issueList = this.issueList;
      let issueListCount = issueList.map((issue) => {
        return { name: issue, count: 0 };
      });
      incidents.forEach((incident) => {
        issueListCount = issueListCount.map((issue) => {
          if (incident.IncidentIssueCategory.IssueType.name == issue.name) {
            let count = issue.count;
            count += 1;
            return { count: count, name: issue.name };
          } else {
            return issue;
          }
        });
      });

      let chartDataByIssue = {
        labels: [],
        datasets: [
          {
            backgroundColor: ["#81D4FA", "#FFF59D", "#A5D6A7", "#FF8A65"],
            data: [],
          },
        ],
      };
      issueListCount.forEach((item) => {
        chartDataByIssue.labels.push(item.name);
        chartDataByIssue.datasets[0].data.push(item.count);
      });
      this.chartDataByIssue = chartDataByIssue;
      this.issueLoaded = true;
    },

    getChartValuesforReporter() {
      let reporter = [];
      reporter.push(this.selectedClientId.split("-")[0].toUpperCase(), "BKS");

      let incidents = this.incidents;
      let reporterCount = reporter.map((reporter) => {
        return { name: reporter, count: 0 };
      });
      incidents.forEach((incident) => {
        reporterCount = reporterCount.map((reporter) => {
          if (incident.reporter == reporter.name) {
            let count = reporter.count;
            count += 1;
            return { count: count, name: reporter.name };
          } else {
            return reporter;
          }
        });
      });

      let chartDataByReporter = {
        labels: [],
        datasets: [
          {
            backgroundColor: ["#1E88E5", "#81D4FA"],
            data: [],
          },
        ],
      };
      reporterCount.forEach((item) => {
        chartDataByReporter.labels.push(item.name);
        chartDataByReporter.datasets[0].data.push(item.count);
      });
      this.chartDataByReporter = chartDataByReporter;
      this.reporterLoaded = true;
    },

    async getChartValuesforShop() {
      await this.getShopList();
      let incidents = this.incidents;
      let shopList = this.shopList;
      let shopListCount = shopList.map((shop) => {
        return { name: shop.id, count: 0 };
      });
      incidents.forEach((incident) => {
        shopListCount = shopListCount.map((shop) => {
          if (incident.shopId == shop.name) {
            let count = shop.count;
            count += 1;
            return { count: count, name: shop.name };
          } else {
            return shop;
          }
        });
      });

      let chartDataByShop = {
        labels: [],
        datasets: [
          {
            backgroundColor: "#1E88E5",
            data: [],
          },
        ],
      };
      shopListCount.forEach((item) => {
        chartDataByShop.labels.push(item.name);
        chartDataByShop.datasets[0].data.push(item.count);
      });
      this.chartDataByShop = chartDataByShop;
      this.shopLoaded = true;
    },

    getChartValuesforStatus() {
      let incidents = this.incidents;
      let statusList = ["pending", "on-process", "solved"];
      let statusListCount = statusList.map((status) => {
        return { name: status, count: 0 };
      });
      incidents.forEach((incident) => {
        statusListCount = statusListCount.map((status) => {
          if (incident.status == status.name) {
            let count = status.count;
            count += 1;
            return { count: count, name: status.name };
          } else {
            return status;
          }
        });
      });

      let chartDataByStatus = {
        labels: [],
        datasets: [
          {
            backgroundColor: ["#EF5350", "#FFF59D", "#A5D6A7"],
            data: [],
          },
        ],
      };
      statusListCount.forEach((item) => {
        chartDataByStatus.labels.push(item.name);
        chartDataByStatus.datasets[0].data.push(item.count);
      });
      this.chartDataByStatus = chartDataByStatus;
      this.statusLoaded = true;
    },
  },
};
</script>