var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-16",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"12"}},[_c('h2',[_vm._v("Live Status")])])],1),_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{staticClass:"d-flex justify-left",attrs:{"cols":"12","md":"2"}},[_c('h3',[_vm._v("Camera")])]),_c('v-spacer'),_c('v-col',{staticClass:"d-flex justify-sm-start justify-lg-center"},[_c('span',{staticClass:"px-2"},[_c('status-indicator',{attrs:{"status":"active"}}),_vm._v(" Online")],1),_c('span',{staticClass:"px-2"},[_c('status-indicator',{attrs:{"status":"negative"}}),_vm._v(" Offline")],1),_c('span',{staticClass:"px-2"},[_c('status-indicator',{attrs:{"status":"positive"}}),_vm._v(" No Frame")],1),_c('span',{staticClass:"px-2"},[_c('status-indicator',{attrs:{"status":"intermediary"}}),_vm._v(" No Video")],1)])],1),(!_vm.cameraShopLoaded)?_c('v-row',{staticClass:"d-flex align-center justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_c('v-row',{staticClass:"d-flex align-center mt-4",attrs:{"no-gutters":""}},[_vm._l((_vm.allShops.length),function(n){return [(_vm.allShops[n - 1].liveStatus == 'offline')?_c('v-col',{key:n,attrs:{"md":"2"}},[_c('v-card',{staticClass:"py-2 d-flex justify-center",attrs:{"outlined":"","color":"transparent"},on:{"click":function($event){return _vm.getShopDetails(
              _vm.allShops[n - 1].id,
              _vm.allShops[n - 1].shopId,
              _vm.allShops[n - 1].cameraId
            )}}},[_c('span',[_c('status-indicator',{attrs:{"status":"negative"}})],1),_vm._v("  "+_vm._s(_vm.allShops[n - 1].shopId)+"-"+_vm._s(_vm.allShops[n - 1].cameraId)+" ")])],1):_vm._e(),(_vm.allShops[n - 1].liveStatus == 'online')?_c('v-col',{key:n,attrs:{"md":"2"}},[_c('v-card',{staticClass:"py-2 d-flex justify-center",attrs:{"outlined":"","color":"transparent"},on:{"click":function($event){return _vm.getShopDetails(
              _vm.allShops[n - 1].id,
              _vm.allShops[n - 1].shopId,
              _vm.allShops[n - 1].cameraId
            )}}},[_c('span',[_c('status-indicator',{attrs:{"status":"active"}})],1),_vm._v("  "+_vm._s(_vm.allShops[n - 1].shopId)+"-"+_vm._s(_vm.allShops[n - 1].cameraId)+" ")])],1):_vm._e(),(_vm.allShops[n - 1].liveStatus == 'noFrame')?_c('v-col',{key:n,attrs:{"md":"2"}},[_c('v-card',{staticClass:"py-2 d-flex justify-center",attrs:{"outlined":"","color":"transparent"},on:{"click":function($event){return _vm.getShopDetails(
              _vm.allShops[n - 1].id,
              _vm.allShops[n - 1].shopId,
              _vm.allShops[n - 1].cameraId
            )}}},[_c('span',[_c('status-indicator',{attrs:{"status":"positive"}})],1),_vm._v("  "+_vm._s(_vm.allShops[n - 1].shopId)+"-"+_vm._s(_vm.allShops[n - 1].cameraId)+" ")])],1):_vm._e(),(_vm.allShops[n - 1].liveStatus == 'noVideo')?_c('v-col',{key:n,attrs:{"md":"2"}},[_c('v-card',{staticClass:"py-2 d-flex justify-center",attrs:{"outlined":"","color":"transparent"},on:{"click":function($event){return _vm.getShopDetails(
              _vm.allShops[n - 1].id,
              _vm.allShops[n - 1].shopId,
              _vm.allShops[n - 1].cameraId
            )}}},[_c('span',[_c('status-indicator',{attrs:{"status":"intermediary"}})],1),_vm._v("  "+_vm._s(_vm.allShops[n - 1].shopId)+"-"+_vm._s(_vm.allShops[n - 1].cameraId)+" ")])],1):_vm._e()]})],2),(_vm.selectedClientId == 'tfs-people-analytics')?_c('v-row',{staticClass:"d-flex align-center"},[_c('v-col',{staticClass:"d-flex justify-left",attrs:{"cols":"12","md":"2"}},[_c('h3',[_vm._v("Sensor")])])],1):_vm._e(),(!_vm.streamShopLoaded)?_c('v-row',{staticClass:"d-flex align-center justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":""}})],1):_c('v-row',{staticClass:"d-flex align-center mt-4",attrs:{"no-gutters":""}},[_vm._l((_vm.shopsByStream.length),function(n){return [(_vm.shopsByStream[n - 1].status == 'offline')?_c('v-col',{key:n,attrs:{"md":"2"}},[_c('v-card',{staticClass:"py-2 d-flex justify-center",attrs:{"outlined":"","color":"transparent"},on:{"click":function($event){return _vm.getShopDetails(
              _vm.shopsByStream[n - 1].id,
              _vm.shopsByStream[n - 1].shopId,
              _vm.shopsByStream[n - 1].cameraId
            )}}},[_c('span',[_c('status-indicator',{attrs:{"status":"negative"}})],1),_vm._v("  "+_vm._s(_vm.shopsByStream[n - 1].id)+" ")])],1):_vm._e(),(_vm.shopsByStream[n - 1].status == 'online')?_c('v-col',{key:n,attrs:{"md":"2"}},[_c('v-card',{staticClass:"py-2 d-flex justify-center",attrs:{"outlined":"","color":"transparent"},on:{"click":function($event){return _vm.getShopDetails(
              _vm.shopsByStream[n - 1].id,
              _vm.shopsByStream[n - 1].shopId,
              _vm.shopsByStream[n - 1].cameraId
            )}}},[_c('span',[_c('status-indicator',{attrs:{"status":"active"}})],1),_vm._v("  "+_vm._s(_vm.shopsByStream[n - 1].id)+" ")])],1):_vm._e()]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }