import APIManager from "./APIManager";

const fetchIssueRecord = async () => {
    const project = await APIManager.axios
        .get(`/v1/operation/issue`)
        .then((response) => {
            return response.data.data;
        })
        .catch((err) => {
            return err.response.data;
        });

    return project;
};

export default{fetchIssueRecord};