<template>
  <v-container style="max-width: 100% !important;">
    <v-row>
      <v-col cols="12" >
        <v-data-table
          :search="search"
          :headers="headers"
          :items="tableItems"
          item-key="id"
          class="elevation-1"
          :loading="loader"
        >
          <template v-slot:item.statusValue="{ item }">
            <v-chip
              id="chip"
              :class="item.statusValue"
              class="font-weight-bold"
              >{{ item.statusValue }}</v-chip
            >
          </template>

          <template v-slot:item.sla="{ item }">
            <v-icon color="green" v-if="item.sla == 'met'">mdi-check</v-icon>
            <v-icon color="red" v-if="item.sla == 'breached'">mdi-close</v-icon>
          </template>

          <template v-slot:item.closedDate="{item}">
            <span v-if="item.sla">{{item.closedDate}}</span>
            <span v-else>-</span>
          </template>

          <template v-slot:item.action="{ item }">
            <v-row>
              <v-col cols="6" md="6">
                <v-btn id="button" tile small @click="$emit('viewDetails', item.id)">
                  <v-icon left>mdi-eye</v-icon
                >View
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    create: { type: Function },
    title: String,
    buttonTitle: String,
    headers: Array,
    tableItems: Array,
    chipStatus: String,
    loader: Boolean,
    viewDetails: Boolean,
    deleteButton: Boolean,
  },
  data: () => ({
    search: "",
    valid: true,
    errorMessage: false,
  }),
  methods: {
    cancel() {
      this.$refs.form.reset();
      this.props.dialog = false;
    },
    getId(id) {
      this.$emit("getId", id);
    },
  },
};
</script>