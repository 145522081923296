<template>
  <v-container fluid class="pa-16">
    <v-row>
      <v-col cols="12" class="d-flex justify-start">
        <h2>Live Status</h2>
      </v-col>
    </v-row>

    <!-- Camera -->
    <v-row class="d-flex align-center">
      <v-col cols="12" md="2" class="d-flex justify-left">
        <h3>Camera</h3>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="d-flex justify-sm-start justify-lg-center">
        <span class="px-2"
          ><status-indicator status="active" />&nbsp;Online</span
        >
        <span class="px-2"
          ><status-indicator status="negative"></status-indicator
          >&nbsp;Offline</span
        >
        <span class="px-2"
          ><status-indicator status="positive"></status-indicator>&nbsp;No
          Frame</span
        >
        <span class="px-2"
          ><status-indicator status="intermediary"></status-indicator>&nbsp;No
          Video</span
        >
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-center" v-if="!cameraShopLoaded">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-row>
    <v-row no-gutters class="d-flex align-center mt-4" v-else>
      <template v-for="n in allShops.length">
        <v-col :key="n" v-if="allShops[n - 1].liveStatus == 'offline'" md="2">
          <v-card
            class="py-2 d-flex justify-center"
            outlined
            color="transparent"
            @click="
              getShopDetails(
                allShops[n - 1].id,
                allShops[n - 1].shopId,
                allShops[n - 1].cameraId
              )
            "
          >
            <span><status-indicator status="negative"></status-indicator></span>
            &nbsp;{{ allShops[n - 1].shopId }}-{{ allShops[n - 1].cameraId }}
          </v-card>
        </v-col>
        <v-col :key="n" v-if="allShops[n - 1].liveStatus == 'online'" md="2">
          <v-card
            class="py-2 d-flex justify-center"
            outlined
            color="transparent"
            @click="
              getShopDetails(
                allShops[n - 1].id,
                allShops[n - 1].shopId,
                allShops[n - 1].cameraId
              )
            "
          >
            <span><status-indicator status="active" /></span>
            &nbsp;{{ allShops[n - 1].shopId }}-{{ allShops[n - 1].cameraId }}
          </v-card>
        </v-col>
        <v-col :key="n" v-if="allShops[n - 1].liveStatus == 'noFrame'" md="2">
          <v-card
            class="py-2 d-flex justify-center"
            outlined
            color="transparent"
            @click="
              getShopDetails(
                allShops[n - 1].id,
                allShops[n - 1].shopId,
                allShops[n - 1].cameraId
              )
            "
          >
            <span><status-indicator status="positive"></status-indicator></span>
            &nbsp;{{ allShops[n - 1].shopId }}-{{ allShops[n - 1].cameraId }}
          </v-card>
        </v-col>
        <v-col :key="n" v-if="allShops[n - 1].liveStatus == 'noVideo'" md="2">
          <v-card
            class="py-2 d-flex justify-center"
            outlined
            color="transparent"
            @click="
              getShopDetails(
                allShops[n - 1].id,
                allShops[n - 1].shopId,
                allShops[n - 1].cameraId
              )
            "
          >
            <span
              ><status-indicator status="intermediary"></status-indicator
            ></span>
            &nbsp;{{ allShops[n - 1].shopId }}-{{ allShops[n - 1].cameraId }}
          </v-card>
        </v-col>
      </template>
    </v-row>

    <!-- Stream -->
    <v-row
      class="d-flex align-center"
      v-if="selectedClientId == 'tfs-people-analytics'"
    >
      <v-col cols="12" md="2" class="d-flex justify-left">
        <h3>Sensor</h3>
      </v-col>
    </v-row>
    <v-row class="d-flex align-center justify-center" v-if="!streamShopLoaded">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-row>
    <v-row no-gutters class="d-flex align-center mt-4" v-else>
      <template v-for="n in shopsByStream.length">
        <v-col :key="n" v-if="shopsByStream[n - 1].status == 'offline'" md="2">
          <v-card
            class="py-2 d-flex justify-center"
            outlined
            color="transparent"
            @click="
              getShopDetails(
                shopsByStream[n - 1].id,
                shopsByStream[n - 1].shopId,
                shopsByStream[n - 1].cameraId
              )
            "
          >
            <span><status-indicator status="negative"></status-indicator></span>
            &nbsp;{{ shopsByStream[n - 1].id }}
          </v-card>
        </v-col>
        <v-col :key="n" v-if="shopsByStream[n - 1].status == 'online'" md="2">
          <v-card
            class="py-2 d-flex justify-center"
            outlined
            color="transparent"
            @click="
              getShopDetails(
                shopsByStream[n - 1].id,
                shopsByStream[n - 1].shopId,
                shopsByStream[n - 1].cameraId
              )
            "
          >
            <span><status-indicator status="active" /></span>
            &nbsp;{{ shopsByStream[n - 1].id }}
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { StatusIndicator } from "vue-status-indicator";
import StatusService from "../services/LiveStatusService";

export default {
  name: "Home",
  data: () => ({
    projectList: [],
    selectedClientId: "",
    allShops: [],
    selectedShop: [],
    cameraShopLoaded: false,
    streamShopLoaded: false,
    shopsByStream: [],
  }),
  created() {
    if (!this.selectedClientId) {
      this.selectedClientId = localStorage.getItem("selectedClientId");
    }
    this.getStatusRecordByCamera();
    if (this.selectedClientId == "tfs-people-analytics") {
      this.getStatusRecordByStream();
    }
  },
  mounted() {
    window.addEventListener(
      "selectedClientId-localstorage-changed",
      (event) => {
        this.selectedClientId = event.detail.storage;
      }
    );
  },
  watch: {
    selectedClientId() {
      this.getStatusRecordByCamera();
      if (this.selectedClientId == "tfs-people-analytics") {
        this.getStatusRecordByStream();
      }
      else{
        this.shopsByStream = [];
        this.streamShopLoaded = true;
      }
    },
  },
  methods: {
    async getStatusRecordByCamera() {
      var onlineShops = [];
      var offlineShops = [];
      var noFrameShops = [];
      var noVideoShops = [];
      this.allShops = [];
      await StatusService.fetchStatusRecordByCamera(this.selectedClientId).then(
        (response) => {
          let requiredData = response;
          for (const item of requiredData) {
            let project = item.Shops.Projects.id;
            if (project == this.selectedClientId) {
              if (item.liveStatus == "online") {
                onlineShops.push(item);
              } else if (item.liveStatus == "offline") {
                offlineShops.push(item);
              } else if (item.liveStatus == "no-frame") {
                noFrameShops.push(item);
              } else if (item.liveStatus == "no-video") {
                noVideoShops.push(item);
              }
            }
          }
          this.allShops = [
            ...offlineShops,
            ...onlineShops,
            ...noFrameShops,
            ...noVideoShops,
          ];
        }
      );
      this.cameraShopLoaded = true;
    },

    async getStatusRecordByStream() {
      this.streamShopLoaded = false;
      var onlineShops = [];
      var offlineShops = [];
      this.shopsByStream = [];
      await StatusService.fetchStatusRecordByStream().then((response) => {
        let requiredData = response;
        Object.entries(requiredData).filter(([key, value]) => {
          if (value.status === "online") {
            onlineShops.push({ id: key, ...value });
          }
          if (value.status === "offline") {
            offlineShops.push({ id: key, ...value });
          }
        });
        this.shopsByStream = [...offlineShops, ...onlineShops];
      });
      this.streamShopLoaded = true;
    },
    getShopDetails(selectedId, shopId, cameraId) {
      this.$router.push(
        `/shop-detail/id=${selectedId}/shopId=${shopId}/cameraId=${cameraId}`
      );
    },
  },
  components: { StatusIndicator },
};
</script>

<style>
:root {
  --status-indicator-size: 20px;
  --status-indicator-color-active: #4bd28f !important;
  --status-indicator-color-negative: #ff4d4d !important;
  --status-indicator-color-positive: rgb(255, 230, 0) !important;
  --status-indicator-color-intermediary: rgb(255, 170, 0) !important;
}

table tbody tr td {
  border-top: none;
  border-bottom: none !important;
}

.col-md-2.col {
    display: flex;
    padding-left: 0;
}
</style>