<template>
  <v-container fluid class="pa-16">
    <v-row>
      <v-col cols="12">
        <h2>Stability Report For {{ month }}</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="2">
        <v-select
          :items="monthList"
          label="Month"
          outlined
          dense
          v-model="selectedMonth"
          style="z-index: 500"
        ></v-select>
      </v-col>
    </v-row>
    <template> </template>
    <v-row class="d-flex align-center justify-center" v-if="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-row>
    <v-row v-if="dataLoaded">
      <v-col cols="12">
        <ve-table
          :columns="headers"
          :table-data="stabilityReport"
          max-height="80vh"
          :fixed-header="true"
        ></ve-table>
      </v-col>
    </v-row>
    <div style="display: none">
      <ToolTip status="7" />
    </div>
  </v-container>
</template>

<script>
import ProjectService from '../services/ProjectService';
import StabilityService from '../services/StabilityService';
import moment from 'moment';
import ToolTip from '../components/ToolTip.vue';

export default {
  data: () => ({
    headers: [
      {
        title: 'Branch',
        key: '0',
        align: 'left',
        field: 'branchId',
        fixed: 'left',
      },
    ],
    shopRecords: {},
    showTooltip: false,
    stabilityReport: [],
    projectList: [],
    month: moment().format('MMMM'),
    selectedClientId: '',
    dataLoaded: false,
    loading: false,
    isPreviousMonth: false,
    isCurrentMonth: true,
    dates: [],
    allRecords : [],
    shopData : [],
    monthList: ['This Month', 'Last Month'],
    selectedMonth: 'This Month',
    currentMonth: moment().format('MMMM'),
    tableOptions: {
      itemsPerPage: -1,
    },
  }),
  components: {
    ToolTip,
  },
  created() {
    if (!this.selectedClientId) {
      this.selectedClientId = localStorage.getItem('selectedClientId');
    }
    this.getProjectRecord();
    this.getHeaders();
  },
  mounted() {
    window.addEventListener(
      'selectedClientId-localstorage-changed',
      (event) => {
        this.selectedClientId = event.detail.storage;
      }
    );
  },
  watch: {
    selectedMonth() {
      if (this.selectedMonth == 'Last Month') {
        this.getPreviousMonthReport();
      } else {
        this.getCurrentMonthReport();
      }
    },
    selectedClientId() {
      this.getStabilityRecord();
    },
  },
  methods: {
    toggleTooltip() {
      this.showTooltip = !this.showTooltip;
    },
    getHeaders() {
      this.headers = [
        {
          title: 'Branch',
          key: '0',
          align: 'left',
          field: 'branchId',
          fixed: 'left',
        },
        {
          title: 'Shop Name',
          key: '1',
          align: 'left',
          field: 'shopName',
        },
        {
          title: 'Opening Time',
          key: '2',
          align: 'left',
          field: 'openingTime',
        },
        {
          title: 'Closing Time',
          key: '3',
          align: 'left',
          field: 'closingTime',
        },
      ];
      let startDate = null;
      let dateDifference = null;
      let dates = [];
      if (this.isCurrentMonth) {
        startDate = moment().startOf('month');
        dateDifference = moment().diff(startDate, 'days');
        let date = startDate;
        dates.push(date);
        for (var i = 0; i < dateDifference; i++) {
          date = moment(date).add(1, 'days');
          dates.push(date);
        }
      } else if (this.isPreviousMonth) {
        startDate = moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD');
        dateDifference = moment()
          .subtract(1, 'months')
          .endOf('month')
          .diff(startDate, 'days');
        let date = startDate;
        dates.push(date);
        for (var x = 0; x < dateDifference; x++) {
          date = moment(date).add(1, 'days');
          dates.push(date);
        }
      }
      dates.forEach((item, index) => {
        this.headers.push({
          title: moment(item).format('DD MMM'),
          key: `${4+index}`,
          field: moment(item).format('YYYY-MM-DD'),
          renderBodyCell: ({ row, column}) => {
            const branchId = row.branchId;
            const date = column.field;
            let text = row[column.field];
            let branchRecords = this.allRecords[branchId][date];
            let shopData = this.shopData[branchId];
            
            return (
              <div>
                <ToolTip status_={text} branchRecords={branchRecords} shopData={shopData}></ToolTip>
              </div>
            );
          },
        });
      });
      this.dates = dates;
    },
    getProjectRecord() {
      ProjectService.fetchProjectRecord().then((response) => {
        let requiredData = response;
        this.projectList = requiredData;
      });
    },
    getProjectId(selectedClientId) {
      this.selectedClientId = selectedClientId;
    },
    async getStabilityRecord() {
      this.stabilityReport = [];
      this.loading = true;
      let requiredObj = null;

      if (this.isCurrentMonth) {
        requiredObj = {
          year: moment().format('YYYY'),
          month: moment().format('M'),
          projectId: this.selectedClientId,
        };
      }

      if (this.isPreviousMonth) {
        requiredObj = {
          year: moment().format('YYYY'),
          month: moment().subtract(1, 'months').format('M'),
          projectId: this.selectedClientId,
        };
      }

      await StabilityService.fetchStabilityRecord(requiredObj).then(
        (response) => {
          let shopsData = response.shops;
          this.shopData =shopsData;

          let demoData = response.data;

          let filteredObj = {};

          Object.keys(demoData).forEach((branch) => {
            filteredObj[branch] = {};
            Object.keys(demoData[branch]).forEach((date) => {
              filteredObj[branch][date] = [];

              demoData[branch][date].forEach((item) => {
                const openingTime = moment(shopsData[branch].openingTime, 'HH:mm');
                const closingTime = moment(shopsData[branch].closingTime, 'HH:mm');
                const currentTime = moment(`${item.hour}:00`, 'HH:mm');

                if (currentTime.isBetween(openingTime, closingTime, null, '[)')) {
                  filteredObj[branch][date].push(item);
                }
              });
            });
          });
          this.allRecords = filteredObj;

          function getStatus(expected, actual) {
            let result = expected - actual;
            if (result == 0) {
              return 1;
            } else if (actual == 0) {
              return 0;
            } else {
              return 2;
            }
          }

          let requiredDemoData = [];
          Object.keys(filteredObj).forEach((branch) => {
            const datesObj = filteredObj[branch];

            let requiredBranchObj = {
              branchId: branch,
              shopName: shopsData[branch]['name'],
              openingTime: shopsData[branch]['openingTime'],
              closingTime: shopsData[branch]['closingTime']
            };


            Object.keys(datesObj).forEach((specificDate) => {
              let tempObj = {
                expectedResult: 0,
                actualResult: 0,
              };

              datesObj[specificDate].forEach((item) => {
                tempObj.expectedResult++;
                tempObj.actualResult += (item.record*1);
               
              });

              requiredBranchObj[specificDate] = getStatus(
                tempObj.expectedResult,
                tempObj.actualResult
              );
            });
            requiredDemoData.push(requiredBranchObj);
          });

          this.stabilityReport = requiredDemoData;
          this.dataLoaded = true;
          this.loading = false;
        }
      );
    },
    getPreviousMonthReport() {
      this.month = moment().subtract(1, 'months').format('MMMM');
      this.isPreviousMonth = true;
      this.isCurrentMonth = false;
      this.getHeaders();
      this.getStabilityRecord();
    },
    getCurrentMonthReport() {
      this.month = moment().format('MMMM');
      this.isPreviousMonth = false;
      this.isCurrentMonth = true;
      this.getHeaders();
      this.getStabilityRecord();
    },
  },
};
</script>

<style scoped>
:root {
  --status-indicator-size: 20px;
  --status-indicator-color-active: #4bd28f !important;
  --status-indicator-color-negative: #ff4d4d !important;
  --status-indicator-color-positive: rgb(75, 89, 210) !important;
  --status-indicator-color-partial: #ff9800 !important;
}
.text-bold {
  font-weight: bold;
}

.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
