import APIManager from "./APIManager";

const fetchIncidentRecord = async (requiredObj) => {
    const project = await APIManager.axios
        .get(`/v1/operation/incident?year=${requiredObj.year}&month=${requiredObj.month}&projectId=${requiredObj.projectId}`)
        .then((response) => {
            return response.data.data;
        })
        .catch((err) => {
            return err.response.data;
        });

    return project;
};

const fetchIndividualIncident = async (id) => {
    const incident = await APIManager.axios
        .get(`/v1/operation/incident/${id}`)
        .then((response) => {
            return response.data.data;
        })
        .catch((err) => {
            return err.response.data;
        });

    return incident;
};

const createIncident = async (requiredObj) => {
    const response = await APIManager.axios.post("/operation/incident", requiredObj).then((res) => {
        return res.data.message
    }).catch((err) => {
        return err.response.data;
    })

    return response;
};

const createIncidentDetail = async (id, requiredObj) => {
    const response = await APIManager.axios.post(`/v1/operation/incident/${id}/detail`, requiredObj).then((res) => {
        return res.data.message
    }).catch((err) => {
        return err.response.data;
    })

    return response;
};

export default {
    fetchIncidentRecord,
    fetchIndividualIncident,
    createIncident,
    createIncidentDetail
};