<template>
  <v-container fluid class="pa-16">
    <v-row >
      <v-col>
        <v-breadcrumbs :items="breadcrumbItems" class="pa-0 ma-0">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h2>Dashboard</h2>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-text class="text--primary">
            <v-row>
              <v-col cols="12" md="6" class="justify-center align-center">
                <div>
                  <v-chip
                    v-if="projectDetail.id"
                    class="ma-1"
                    style="background: #3da4de; color: #ffffff"
                    >{{ projectDetail.id }}</v-chip
                  >
                  <v-chip
                    v-if="projectDetail.status"
                    class="ma-1"
                    style="background: #3da4de; color: #ffffff"
                    >{{
                      projectDetail.status.charAt(0).toUpperCase() +
                      projectDetail.status.slice(1)
                    }}</v-chip
                  >
                  <v-chip
                    v-if="projectDetail.industry"
                    class="ma-1"
                    style="background: #3da4de; color: #ffffff"
                    >{{
                      projectDetail.industry.charAt(0).toUpperCase() +
                      projectDetail.industry.slice(1)
                    }}</v-chip
                  >
                </div>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  v-model="filter.status"
                  label="Filter by Status"
                  outlined
                  dense
                  solo
                  flat
                  :items="statusList"
                  item-text="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  v-model="filter.group"
                  label="Filter by Group"
                  outlined
                  dense
                  solo
                  flat
                  :items="groupList"
                ></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete
                  v-model="filter.province"
                  label="Filter by Province"
                  outlined
                  dense
                  solo
                  flat
                  :items="provinceList"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filteredShops"
                  :items-per-page="10"
                  :loading="loading"
                  style="width: 100%"
                >
                  <template v-slot:item.status="{ item }">
                    <v-chip
                      id="chip"
                      :class="item.status"
                      class="font-weight-bold"
                      >{{ item.status }}</v-chip
                    >
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn id="button" small @click="viewDetails(item.id)">
                      <v-icon>mdi-home-variant</v-icon>
                      Shop Details
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProjectService from "@/services/ProjectService";
import ShopService from "@/services/ShopService";

export default {
  name: "Dashboard",
  data: () => ({
    selectedClientId: "",
    selectedShop: "",
    selectedStatus: "",
    selectedGroup: "",
    selectedProvince: "",
    projectDetail: {},
    shopList: [],
    statusList: ["Active", "Inactive"],
    groupList: [],
    provinceList: [],
    filteredShops: [],
    headers: [
      { text: "Shop Id", align: "left", sortable: true, value: "id" },
      { text: "Name", align: "left", sortable: true, value: "name" },
      { text: "Province", align: "left", sortable: true, value: "province" },
      { text: "Group", align: "left", sortable: true, value: "group" },
      {
        text: "No. of Cameras",
        align: "left",
        sortable: true,
        value: "nrofCameras",
      },
      { text: "Status", align: "left", sortable: true, value: "status" },
      { text: "Action", align: "center", sortable: true, value: "action" },
    ],
    filter: {
      status: null,
      group: null,
      province: null,
    },
    loading: true,
    breadcrumbItems: [
      {
        text: "Dashboard",
        href: "dashboard#/dashboard",
        disabled: true,
      }
    ]
  }),
  created() {
    if (!this.selectedClientId) {
      this.selectedClientId = localStorage.getItem("selectedClientId");
    }
    this.getProjectRecord();
  },
  watch: {
    selectedClientId() {
      this.getProjectRecord();
    },
    filter: {
      handler: function () {
        let filteredShops = this.shopList;
        if (this.filter.status) {
          filteredShops = this.filterByStatus(filteredShops);
        }
        if (this.filter.group) {
          filteredShops = this.filterByGroup(filteredShops);
        }
        if (this.filter.province) {
          filteredShops = this.filterByProvince(filteredShops);
        }
        this.filteredShops = filteredShops;
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener(
      "selectedClientId-localstorage-changed",
      (event) => {
        this.selectedClientId = event.detail.storage;
      }
    );
  },
  methods: {
    async getProjectRecord() {
      const id = this.selectedClientId;
      await ProjectService.getProject(id).then((response) => {
        this.projectDetail = response;
      });
      this.getShopRecord();
    },
    async getShopRecord() {
      const id = this.selectedClientId;
      await ShopService.fetchShopRecord(id).then((response) => {
        let requiredObj = response;
        this.shopList = response;
        requiredObj = requiredObj.map((item) => {
          if (!this.groupList.includes(item.group)) {
            this.groupList.push(item.group);
          }
          if (!this.provinceList.includes(item.province)) {
            this.provinceList.push(item.province);
          }
          if (item.active) {
            return { status: "Active", ...item };
          } else {
            return { status: "Inactive", ...item };
          }
        });
        this.shopList = requiredObj;
        this.filteredShops = this.shopList;
      });
      this.loading = false;
    },
    filterByStatus(shops) {
      console.log(shops);
      let filteredShops = [];
      console.log(this.filter.status);
      shops.forEach((shop) => {
        if (shop.status == this.filter.status) {
          filteredShops.push(shop);
        }
      });
      return filteredShops;
    },
    filterByGroup(shops) {
      let filteredShops = [];
      shops.forEach((shop) => {
        if (shop.group == this.filter.group) {
          filteredShops.push(shop);
        }
      });
      return filteredShops;
    },
    filterByProvince(shops) {
      let filteredShops = [];
      shops.forEach((shop) => {
        if (shop.province == this.filter.province) {
          filteredShops.push(shop);
        }
      });
      return filteredShops;
    },
    viewDetails(shopId) {
      this.$router.push(`/shop/${shopId}`);
    },
  },
};
</script>

<style>
.v-breadcrumbs li {
    font-size: 1rem;
}
</style>