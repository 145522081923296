import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import LiveStatus from '../views/LiveStatus.vue'
import ShopDetail from '../views/ShopDetail.vue'
import Stability from '../views/Stability.vue'
import IncidentReport from '../views/Incident/IncidentReport.vue'
import CreateIncidentReport from '../views/Incident/CreateIncidentReport.vue'
import IncidentDetail from '../views/Incident/IncidentDetail.vue'
import IncidentDashboard from '../views/Incident/IncidentDashboard.vue'
import Dashboard from '../views/Dashboard.vue'
// import moment from 'moment';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: {
      hideNavbar: true,
      isAuth: false
    },
  },
  {
    path: '/live-status',
    name: 'LiveStatus',
    component: LiveStatus,
    meta: {
      isAuth: true
    },
  },
  {
    path: '/shop/:id',
    name: 'ShopDetail',
    component: ShopDetail,
    meta: {
      isAuth: true,
    },
  },
  {
    path: '/stability',
    name: 'Stability',
    component: Stability,
    meta: {
      isAuth: true
    },
  },
  {
    path: '/incident/report',
    name: 'IncidentReport',
    component: IncidentReport,
    meta: {
      isAuth: true
    },
  },
  {
    path: '/incident/dashboard',
    name: 'IncidentDashboard',
    component: IncidentDashboard,
    meta: {
      isAuth: true
    },
  },
  {
    path: '/incident/create',
    name: 'CreateIncidentReport',
    component: CreateIncidentReport,
    meta: {
      isAuth: true
    },
  },
  {
    path: '/incident/detail/:id',
    name: 'IncidentDetail',
    component: IncidentDetail,
    meta: {
      isAuth: true
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      isAuth: true,
      breadcrumb: 'Dashboard'
    },
  },
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.isAuth)) {
    if (!localStorage.getItem("token")) {
      next("/");
    }
    let hours = 24;
    let now = new Date().getTime();
    let setupTime = localStorage.getItem("setupTime");

    if (setupTime == null) {
      localStorage.setItem("setupTime", now);
    } else {
      if (now - setupTime > hours * 60 * 60 * 1000) {
        localStorage.clear();
        localStorage.setItem("setupTime", now);
        next("/");
        window.location.reload();
      }
    }
  }
  next();
});

export default router
