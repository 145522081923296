<template>
  <v-container fluid class="my-10">
    <v-dialog v-model="addIncidentDialog" width="40%">
      <v-card>
        <v-layout>
          <v-flex wrap>
            <v-card>
              <v-card-title>
                <span class="headline">Add Incident Detail</span>
                <v-col class="text-right">
                  <v-btn icon @click="cancel()">
                    <v-icon dark>mdi-close</v-icon>
                  </v-btn>
                </v-col>
              </v-card-title>
              <v-divider></v-divider>
              <v-form ref="form">
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-col cols="12">
                        <v-text-field
                          v-model="newIncidentDetail.description"
                          label="Description"
                          required
                          :rules="descriptionRules"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          :items="reporter"
                          label="Reporter"
                          v-model="newIncidentDetail.reporter"
                          required
                          :rules="reporterRules"
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="6"
                        v-if="newIncidentDetail.reporter == 'BKS'"
                      >
                        <v-radio-group
                          row
                          v-model="newIncidentDetail.level"
                          required
                          :rules="levelRules"
                        >
                          <template v-slot:label>
                            <div>Level</div>
                          </template>
                          <v-radio label="1" value="1"></v-radio>
                          <v-radio label="2" value="2"></v-radio>
                          <v-radio label="3" value="3"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="6" v-else></v-col>
                      <v-col cols="6">
                        <v-menu
                          ref="dateMenu"
                          v-model="dateMenu"
                          :close-on-content-click="false"
                          :return-value.sync="openDate"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="openDate"
                              label="Open Date"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="openDate" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="dateMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="saveDate()">
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-menu
                          ref="openTimeMenu"
                          v-model="timeMenu"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          :return-value.sync="openTime"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="openTime"
                              label="Open Time"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            v-if="timeMenu"
                            v-model="openTime"
                            ampm-in-title
                            full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="timeMenu = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="saveTime()">
                              OK
                            </v-btn></v-time-picker
                          >
                        </v-menu>
                      </v-col>
                      <v-col cols="12" v-if="resolveIssue">
                        <v-radio-group
                          row
                          v-model="newIncidentDetail.sla"
                          required
                          :rules="slaRules"
                        >
                          <template v-slot:label>
                            <div>SLA</div>
                          </template>
                          <v-radio label="Met" value="met"></v-radio>
                          <v-radio label="Breached" value="breached"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-layout>
                  </v-container>
                  <v-divider></v-divider>
                  <br />
                  <v-col class="text-right">
                    <v-btn
                      dense
                      id="button"
                      @click="resolve()"
                      v-if="resolveIssue"
                      :disabled="isDisabled"
                      >Resolve</v-btn
                    >
                    <v-btn
                      dense
                      id="button"
                      @click="addIncidentDetail()"
                      :disabled="isDisabled"
                      v-else
                      >Save</v-btn
                    >
                    <v-btn dense id="button" class="ml-4" @click="cancel()"
                      >Cancel</v-btn
                    >
                  </v-col>
                </v-card-text>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12">
        <h2>SH005</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <div>
          <p>
            {{ incidentDetail.IncidentIssueCategory.IssueType.name }} -
            {{ incidentDetail.IncidentIssueCategory.name }}
          </p>
        </div>
        <div>
          <p>Description: {{ incidentDetail.description }}</p>
        </div>
        <!-- </v-col> -->
        <!-- <v-col cols="12" md="6"> -->
        <!-- <div>
          <p>Category: {{ incidentDetail.IncidentIssueCategory.name }}</p>
        </div> -->
        <div>
          <p>
            Critical Level:
            <span
              ><v-chip
                dense
                id="chip"
                :class="incidentDetail.criticalLevelValue"
                >{{ incidentDetail.criticalLevelValue }}</v-chip
              ></span
            >
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn id="button" :disabled="incidentDetail.sla" @click="showDialog()"
          >Add Incident Detail</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-data-table
        :headers="headers"
        :items="incidentDetail.IncidentDetails"
        style="width: 100%"
      ></v-data-table>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn
          id="button"
          :disabled="incidentDetail.sla"
          @click="showResolveDialog()"
          >Resolve</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IncidentService from "../../services/IncidentService";
// import ProjectService from '../services/ProjectStatus';
import moment from "moment";
export default {
  data: () => ({
    incidentDetail: [],
    headers: [
      {
        text: "Id",
        align: "left",
        sortable: true,
        value: "id",
      },
      { text: "Remark", align: "left", sortable: true, value: "description" },
      {
        text: "Reporter",
        align: "left",
        sortable: true,
        value: "reporter",
      },
      {
        text: "Level",
        align: "left",
        sortable: true,
        value: "level",
      },
      {
        text: "Created At",
        align: "left",
        sortable: true,
        value: "createdAt",
      },
    ],
    reporter: [],
    addIncidentDialog: false,
    newIncidentDetail: {
      description: "",
      reporter: "",
      level: 1,
      status: "on-process",
      sla: "",
      createdAt: ""
    },
    openDate: moment().format('YYYY-MM-DD'),
    openTime: moment().format("h:mm"),
    dateMenu: false,
    timeMenu: false,
    showTimeDialog: false,
    isStarted: false,
    isResolved: false,
    isDisabled: false,
    errorMessage: false,
    resolveIssue: false,
    valid: false,
    descriptionRules: [(v) => !!v || "Description is required"],
    reporterRules: [(v) => !!v || "Reporter is required"],
    levelRules: [(v) => !!v || "Level is required"],
    slaRules: [(v) => !!v || "SLA is required"],
  }),
  created() {
    this.getIncidentDetail();
  },
  methods: {
    async getIncidentDetail() {
      let id = this.$route.params.id;
      await IncidentService.fetchIndividualIncident(id).then((response) => {
        let requiredData = response;
        let criticalLevelValue = null;
        let criticalLevel = [
          { id: 4, name: "Low" },
          { id: 3, name: "Medium" },
          { id: 2, name: "High" },
          { id: 1, name: "Critical" },
        ];
        criticalLevel.forEach((level) => {
          if (level.id == requiredData.criticalLevel) {
            criticalLevelValue = level.name;
          }
        });
        requiredData.openDate = moment(requiredData.createdAt).format(
          "YYYY-MM-DD"
        );
        requiredData.criticalLevelValue = criticalLevelValue;
        this.reporter.push(
          requiredData.IncidentProject.id.split("-")[0].toUpperCase(),
          "BKS"
        );
        this.incidentDetail = requiredData;
      });
    },
    startIncident() {
      this.isStarted = true;
    },
    showDialog() {
      this.resolveIssue = false;
      this.addIncidentDialog = true;
    },
    cancel() {
      this.$refs.form.reset();
      this.addIncidentDialog = false;
    },
    async addIncidentDetail() {
      const id = this.$route.params.id;
      this.validation();
      if (this.valid) {
        await IncidentService.createIncidentDetail(
          id,
          this.newIncidentDetail
        ).then((response) => {
          response; // eslint-disable-line no-unused-vars
          window.location.reload();
        });
      }
    },
    showResolveDialog() {
      this.resolveIssue = true;
      this.addIncidentDialog = true;
    },
    resolve() {
      this.validation();
      if (this.valid) {
        this.isResolved = true;
        this.newIncidentDetail.status = "solved";
        this.addIncidentDetail();
      }
    },
    validation() {
      this.valid = false;
      if (this.$refs.form.validate()) {
        this.valid = true;
        this.isDisabled = true;
      } else {
        this.valid = false;
      }
    },
    saveDate() {
      this.$refs.dateMenu.save(this.openDate);
      this.showTimeDialog = true;
    },
    saveTime() {
      this.$refs.openTimeMenu.save(this.openTime);
      console.log(this.openTime);
      let openDate = moment(this.openDate + " " + this.openTime).format(
        "YYYY-MM-DD h:mm:ss"
      );
      this.newIncidentDetail.createdAt = openDate;
      console.log(this.newIncident);
    },
  },
};
</script>