import APIManager from "./APIManager";

const deleteStream = async (shopId, streamId) => {
    const response = await APIManager.axios
      .delete(`/v1/operation/shop/${shopId}/stream/${streamId}`)
      .then((res) => {
        return res.data.message;
      })
      .catch((err) => {
        return err.response.data;
      });
    return response;
  };

export default {
    deleteStream
};