<template>
  <v-container class="my-10">
    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid
        fill-height
        style="background-color: rgba(255, 255, 255, 0.5)"
      >
        <v-layout justify-center align-center>
          <v-progress-circular indeterminate color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-card>
      <v-card-title>Create Incident Report</v-card-title>
      <v-card-text style="width: 100%">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="3">
              <v-select
                required
                :rules="projectRules"
                v-model="newIncident.projectId"
                label="Project"
                :items="projectList"
                item-text="name"
                item-value="id"
                @change="selectShop()"
                style="width: 100%"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-if="showShop"
                required
                :rules="shopRules"
                v-model="newIncident.shopId"
                label="Shop"
                :items="shopList"
                style="width: 100%"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-select
                required
                :rules="issueRules"
                :items="issueTypeList"
                v-model="selectedIssue"
                @change="selectCategory()"
                label="Issue Type"
                style="width: 100%"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-if="showCategory"
                :items="issueCategory"
                item-text="name"
                item-value="id"
                required
                :rules="categoryRules"
                v-model="newIncident.issueCategoryId"
                label="Category"
                style="width: 100%"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-select
                required
                :rules="criticalLevelRules"
                v-model="newIncident.criticalLevel"
                label="Critical Level"
                :items="criticalLevel"
                item-text="name"
                item-value="id"
                style="width: 100%"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-select
                required
                :rules="reporterRules"
                v-model="newIncident.reporter"
                label="Reporter"
                :items="reporter"
                style="width: 100%"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                required
                :rules="descriptionRules"
                v-model="newIncident.description"
                label="Description"
                style="width: 100%"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="openDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="openDate"
                    label="Open Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="openDate" no-title scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="saveDate()"> OK </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="3">
              <v-menu
                ref="openTimeMenu"
                v-model="timeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="openTime"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="openTime"
                    label="Open Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timeMenu"
                  v-model="openTime"
                  ampm-in-title
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="timeMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="saveTime()">
                    OK
                  </v-btn></v-time-picker
                >
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-btn @click="validation()" id="button" :disabled="isDisabled">Submit</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ProjectService from "../../services/ProjectService";
import IncidentService from "../../services/IncidentService";
import IssueService from "../../services/IssueService";
import moment from "moment";
export default {
  name: "CreateIncident",
  data: () => ({
    newIncident: {
      shopId: "",
      issueCategoryId: null,
      description: "",
      criticalLevel: "",
      reporter: "",
      projectId: "",
      status: "pending",
      sla: null,
      createdAt: null,
    },
    criticalLevel: [
      { id: 4, name: "Low" },
      { id: 3, name: "Medium" },
      { id: 2, name: "High" },
      { id: 1, name: "Critical" },
    ],
    reporter: [],
    showShop: false,
    showCategory: false,
    projectList: [],
    shopList: [],
    issueTypeRecord: [],
    issueTypeList: [],
    issueCategory: [],
    selectedIssue: "",
    openDate: moment().format('YYYY-MM-DD'),
    openTime: moment().format("h:mm"),
    dateMenu: false,
    timeMenu: false,
    showTimeDialog: false,
    loading: false,
    valid: false,
    isDisabled: false,
    projectRules: [v => !!v || 'Project is required'],
    shopRules: [v => !!v || 'Shop is required'],
    issueRules: [v => !!v || 'Issue is required'],
    reporterRules: [v => !!v || 'Reporter is required'],
    categoryRules: [v => !!v || 'Category is required'],
    criticalLevelRules: [v => !!v || 'Critical Level is required'],
    descriptionRules: [v => !!v || 'Description is required'],
  }),
  created() {
    this.getProjectList();
    this.getIssueType();
  },
  methods: {
    selectShop() {
      this.showShop = true;
      this.projectList.forEach((project) => {
        if (project.id == this.newIncident.projectId) {
          project.Shops.forEach((shop) => {
            this.shopList.push(shop.id);
          });
        }
        this.getReporter();
      });
    },
    getProjectList() {
      ProjectService.fetchProjectRecord().then((response) => {
        let requiredData = response;
        this.projectList = requiredData;
      });
    },
    getReporter() {
      let reporter = [];
      reporter.push(
        this.newIncident.projectId.split("-")[0].toUpperCase(),
        "BKS"
      );
      this.reporter = reporter;
    },
    async createIncident() {
      await IncidentService.createIncident(this.newIncident)
        .then(() => {
          this.$router.push("/incident-report");
        })
        .catch((err) => {
            console.log(err);
          
});
    },
    getIssueType() {
      IssueService.fetchIssueRecord().then((response) => {
        this.issueTypeRecord = response;
        this.issueTypeRecord.forEach((item) => {
          this.issueTypeList.push(item.name);
        });
      });
    },
    selectCategory() {
      this.showCategory = true;
      this.issueCategory = [];
      this.issueTypeRecord.forEach((record) => {
        if (record.name == this.selectedIssue) {
          record.IssueCategories.forEach((category) => {
            this.issueCategory.push(category);
          });
        }
      });
    },
    validation(){
      if(this.$refs.form.validate()){
        this.valid = true;
        this.createIncident();
        this.isDisabled = true;
      }
      else{
        this.valid = false;
      }
    },
    saveDate() {
      this.$refs.dateMenu.save(this.openDate);
      this.showTimeDialog = true;
    },
    saveTime() {
      this.$refs.openTimeMenu.save(this.openTime);
      console.log(this.openTime);
      let openDate = moment(this.openDate + " " + this.openTime).format(
        "YYYY-MM-DD h:mm:ss"
      );
      this.newIncident.createdAt = openDate;
      console.log(this.newIncident.createdAt);
    },
  },
};
</script>