import APIManager from "./APIManager";

const fetchStabilityRecord = async (queryObj) => {
    const status = await APIManager.axios
      .get(`/v1/operation/stability/?year=${queryObj.year}&month=${queryObj.month}&projectId=${queryObj.projectId}`, queryObj)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err.response.data;
      });
  
    return status;
  };
  export default {
    fetchStabilityRecord,
  };